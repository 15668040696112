import { stringify } from 'query-string';
import {
    Identifier,
    PaginationPayload,
    SortPayload,
    FilterPayload,
    fetchUtils,
    DataProvider,
} from 'ra-core';
import { transform, isEqual, isObject } from 'lodash';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;


const apiUrl = `${API_URL}/api/admin`;


const getPaginationQuery = (pagination: PaginationPayload) => {
    return {
        page: pagination.page,
        page_size: pagination.perPage,
    };
};

const getFilterQuery = (filter: FilterPayload) => {
    const { q: search, ...otherSearchParams } = filter;
    return {
        ...otherSearchParams,
        search,
    };
};

export const getOrderingQuery = (sort: SortPayload) => {
    const { field, order } = sort;
    return {
        ordering: `${order === 'ASC' ? '' : '-'}${field}`,
    };
};




export default (
    httpClient: Function = fetchUtils.fetchJson
): DataProvider => {
    const getOneJson = (resource: String, id: Identifier, extraAction: string = '') =>
        httpClient(`${apiUrl}/${resource}/${id}/${extraAction}`).then(
            (response: Response) => response.json
        );

    const diff = (object: any, base: any) => {
        return transform(object, (result: any, value, key) => {
            if (!isEqual(value, base[key])) {
                result[key] =
                    isObject(value) && isObject(base[key]) ? diff(value, base[key]) : value;
            }
        });
    };
    // Función para subir archivo a S3
    async function uploadFileToS3(file: File): Promise<[any,any]> {
        try {
            // Obtener URL firmada
            console.log("file", file)
            const { data: signedUrlResponse } = await axios.get(`${API_URL}/api/presigned-url/`, {
                params: {
                    file_name: file.name,
                    file_type: file.type
                }
            });

            // Convertir archivo a ArrayBuffer
            const fileData = await new Promise<ArrayBuffer>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as ArrayBuffer);
                reader.onerror = reject;
                reader.readAsArrayBuffer(file);
            });

            // Subir archivo a S3 usando la URL firmada
            const { data: fileResponse } = await axios.put(signedUrlResponse.url, fileData, {
                headers: {
                    'Content-Type': file.type
                }
            });

            return [signedUrlResponse, fileResponse];
        } catch (error) {
            console.error('Error uploading to S3:', error);
            throw error;
        }
    }


    return {
        getList: async (resource, params) => {
            const query = {
                ...getFilterQuery(params.filter),
                ...getPaginationQuery(params.pagination),
                ...getOrderingQuery(params.sort),
            };
            const url = `${apiUrl}/${resource}/?${stringify(query)}`;
            const { json } = await httpClient(url);

            //console.log(url)

            return {
                data: json.results,
                total: json.count,
            };
        },

        getOne: async (resource, params) => {

            let action: string = '';
            if (params.meta) {
                action = params.meta.action ? params.meta.action : '';
            }
            const data = await getOneJson(resource, params.id, action);
            return {
                data,
            };
        },

        getMany: async (resource, params) => {
            //Por
            return Promise.all(
                params.ids.map(id => getOneJson(resource, id))
            ).then(data => ({ data }));
        },

        getManyReference: async (resource, params) => {
            const query = {
                ...getFilterQuery(params.filter),
                ...getPaginationQuery(params.pagination),
                ...getOrderingQuery(params.sort),
                [params.target]: params.id,
            };
            const url = `${apiUrl}/${resource}/?${stringify(query)}`;
            const { json } = await httpClient(url);

            return {
                data: json.results,
                total: json.count,
            };
        },

        update: async (resource, params) => {
            let newdata = diff(params.data, params.previousData);
            if (['oldusers'].includes(resource)) {
                let formData = new FormData();
                console.log("parametros", params)
                for (const key in params.data) {
                    if (params.data[key]) {
                        if (['profile_picture'].includes(key)) {
                            if (params.data[key] && typeof params.data[key] != "string") {
                                formData.append(key, params.data[key]?.rawFile)
                            }
                            continue;
                        }
                        if (['first_name', 'last_name', 'phone', 'ig_user', 'tiktok_user', 'is_staff', 'old_user', 'country', 'segments', 'roles', 'email', 'country'].includes(key)) {
                            formData.append(key, params.data[key])
                            continue;
                        }
                    }
                }
                console.log(formData)
                const object: { [key: string]: any } = {};
                formData.forEach((value: any, key: any) => {
                    object[key] = value;
                });

                var data = JSON.stringify(object);
                const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}/`, {
                    method: 'PATCH',
                    body: data,
                });
                return { data: json };

            }

            if (['products', 'posts', 'users', 'benefits'].includes(resource)) {
                let formData = new FormData();
                console.log(params)
                for (const key in params.data) {
                    if(params.data[key] === false || params.data[key] === true){
                        console.log("variable", key , params.data[key]);
                        formData.append(key, params.data[key]);
                    }
                    if (['segments'].includes(key)) {
                        const segments = params.data[key]
                        if(segments.length > 0){
                            segments.map((segment: any, i: number) => {
                                formData.append("segments[" + i + "]", segment)
                            });  
                        }
                        else{
                            formData.append("segments", "")
                        }
                        continue;
                    }
                    if (params.data[key]) {
                        if (['content_file', 'profile_picture', 'attachment'].includes(key)) {
                            if (params.data[key] && params.data[key].rawFile) {
                                formData.append(key, params.data[key]?.rawFile)
                            }
                            continue;
                        }
                        if (['roles'].includes(key)) {
                            const roles = params.data[key]
                            roles.map((roles: any, i: number) => {
                                formData.append("roles[" + i + "]", roles)
                            });
                            continue;
                        }
                        if (['contents'].includes(key)) {
                            // const contents = params.data[key]
                            // contents.map((content: any, i: number) => {
                            //     formData.append("contents[" + i + "]content_file", content.rawFile)
                            //     formData.append("contents[" + i + "]content_type", content.rawFile.type)
                            // });
                            continue;
                        }
                        if (['place'].includes(key)) {
                            console.log("place for upload " , params.data[key])
                            if(params.data[key]){
                                formData.append("place.google_place_id", params.data[key].placeId)
                                formData.append("place.name", params.data[key].name)
                                formData.append("place.url", params.data[key].url)
                                formData.append("place.address",params.data[key].address.streetAddress)
                                formData.append("place.city",params.data[key].address.locality)
                                formData.append("place.region",params.data[key].address.region)
                                formData.append("place.country",params.data[key].address.country)
                            }
                            continue;
                        }

                        if (['albums'].includes(key)) {
                            const albums = params.data[key]
                            albums.map((album: any, i: number) => {
                                formData.append("albums", album)
                            });
                            continue;
                        }


                        if (['categories'].includes(key)) {
                            const categories = params.data[key]
                            categories.map((categorie: any, i: number) => {
                                formData.append("categories", categorie)
                            });
                            continue;
                        }


                        if (['details'].includes(key)) {
                            const details = params.data[key]
                            details.map((detail: any, i: number) => {
                                formData.append("details", detail)
                            });
                            continue;
                        }
                       
                        formData.append(key, params.data[key]);
                    }

                }
                console.log("PATCH", formData.values())
                const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}/`, {
                    method: 'PATCH',
                    body: formData,
                });
                return { data: json };
            };





            const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}/`, {
                method: 'PATCH',
                body: JSON.stringify(newdata),
            });
            return { data: json };
        },

        updateMany: async (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}/`, {
                        method: 'PATCH',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

        create: async (resource, params) => {
            if (['products', 'posts', 'users', 'benefits','searchbanners'].includes(resource)) {
                let formData = new FormData();

                console.log(params)
                for (const key in params.data) {
                    if (params.data[key]) {

                        if (['content_file', 'profile_picture', 'attachment',"image"].includes(key)) {
                            if (params.data[key]) {
                                formData.append(key, params.data[key]?.rawFile)
                            }
                            continue;
                        }
                        if (['segments'].includes(key)) {
                            const segments = params.data[key]
                            segments.map((segment: any, i: number) => {
                                formData.append("segments[" + i + "]", segment)
                            });
                            continue;
                        }
                        if (['roles'].includes(key)) {
                            const roles = params.data[key]
                            roles.map((roles: any, i: number) => {
                                formData.append("roles[" + i + "]", roles)
                            });
                            continue;
                        }
                        if (['contents'].includes(key)) {
                            const contents = params.data[key]?.files
                            const ratio = params.data[key]?.ratioSquare ?  "1:1" : "4:3" ;
                            const uploadedFiles = [];
                            for (let i = 0; i < contents.length; i++) {
                                const content = contents[i];
                                const [signedUrlResponse, file_response] = await uploadFileToS3(content);
                                console.log("s3Url : ", signedUrlResponse);
                                uploadedFiles.push({
                                    content_file: signedUrlResponse.url.replace('https://cbc-premedia.s3.amazonaws.com/', '').split('?AWSAccessKeyId')[0].replace('dev/', ''),
                                    content_type: content.type,
                                    content_ratio: ratio
                                });
                            }
                            uploadedFiles.forEach((file, i) => {
                                formData.append(`contents[${i}]content_file`, file.content_file);
                                formData.append(`contents[${i}]content_type`, file.content_type);
                                formData.append(`contents[${i}]content_ratio`, file.content_ratio);
                            });
                            console.log("formData", formData.values())
                            continue;
                        }
                        if (['place'].includes(key)) {
                            console.log("place for upload " , params.data[key])
                            formData.append("place.google_place_id", params.data[key].placeId)
                            formData.append("place.name", params.data[key].name)
                            formData.append("place.url", params.data[key].url)
                            formData.append("place.address",params.data[key].address.streetAddress)
                            formData.append("place.city",params.data[key].address.locality)
                            formData.append("place.region",params.data[key].address.region)
                            formData.append("place.country",params.data[key].address.country)
                            continue;
                        }

                        if (['albums'].includes(key)) {
                            const albums = params.data[key]
                            albums.map((album: any, i: number) => {
                                formData.append("albums[" + i + "]", album)
                            });
                            continue;
                        }


                        if (['categories'].includes(key)) {
                            const categories = params.data[key]
                            categories.map((categorie: any, i: number) => {
                                formData.append("categories[" + i + "]", categorie)
                            });
                            continue;
                        }


                        if (['details'].includes(key)) {
                            const details = params.data[key]
                            details.map((detail: any, i: number) => {
                                formData.append("details", detail)
                            });
                            continue;
                        }



                        formData.append(key, params.data[key]);
                    }

                }

                const { json } = await httpClient(`${apiUrl}/${resource}/`, {
                    method: 'POST',
                    body: formData,
                });
                return {
                    data: { ...json },
                };
            };

            const { json } = await httpClient(`${apiUrl}/${resource}/`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            });
            return {
                data: { ...json },
            };
        },

        delete: async (resource, params) =>
            httpClient(`${apiUrl}/${resource}/${params.id}/`, {
                method: 'DELETE',
            }).then(() => ({ data: params.previousData })),

        deleteMany: async (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}/`, {
                        method: 'DELETE',
                    })
                )
            ).then(() => ({ data: [] })),

    };
};




export async function enviarSolicitud(userId: number): Promise<void> {
    const apiURL = apiUrl + '/users/' + userId + '/invite/';
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
        console.error('No se encontró el token de acceso.');
        return;
    }


    try {
        const response = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}` // Incluye el token de acceso en el encabezado Authorization
                // Puedes agregar más cabeceras según lo requiera tu API
            },
            body: JSON.stringify("") // Convierte los datos a formato JSON
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log('Respuesta del servidor:', responseData);
            // Realiza acciones adicionales según la respuesta del servidor
        } else {
            console.error('Error al enviar la solicitud:', response.status);
            // Maneja errores de solicitud
        }
    } catch (error) {
        console.error('Error:', error);
        // Maneja errores generales
    }
}



