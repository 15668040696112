import React, { useEffect, useState, useRef } from "react";
import axios from "axios";


export default function ExclusiveInvite() {
    const [invite, setInvite] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

const API_URL = process.env.REACT_APP_API_URL;
async function getInvites(){
  const config = {
    method: "post",
    url: `${API_URL}/api/admin/users/invite_exclusive/`,
    headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
    },
    data: {}
};
  
  try {
    const response = await axios(config);
    setInvite(response.data.invitation_code);
  } catch (err) {
    setError("¡Oops! Hubo un error al generar la invitación");
    alert("¡Oops! Hubo un error al generar la invitación");
    console.error(err);
  }
}

  const handleClick = async () => {
    setLoading(true);
    await getInvites();
    setLoading(false);
    console.log(invite);
  };

 
  return (
  <div className="Generator-container">
    <h1> Generador de Invitaciones</h1>
    <span> Con el siguiente boton puedes generar invitaciones unicas para influencers   Cranberry Chic </span>
    <button onClick={handleClick} disabled={loading}> {loading ? "Generando..." : (invite ? "Generar Nueva Invitacion" : "Generar Invitacion")} </button>
    <div style={{marginTop: "20px"}}>
      {invite && (
        <div style={{
          display: "flex", 
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px"
        }}>
          <input
            type="text"
            value={`https://app.cranberrychic.com/exclusiveregister/code=${invite}`}
            readOnly
            onClick={(e) => e.target.select()}
          />
          <button
            onClick={() => {
              navigator.clipboard.writeText(`https://app.cranberrychic.com/exclusiveregister/code=${invite}`);
            }}

          >
            Copiar
          </button>
        </div>
      )}
    </div>
  </div>
  )
   
}